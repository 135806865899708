html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #003366;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

#root {
  height: 100%;
}

.app-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 140px;
  padding-bottom: 15vh;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.open-btn {
  font-size: x-large;
  background-color: #003366;
  color: white;
  border-radius: 29.5px;
  width: 320px;
  height: 59px;
  margin-bottom: 2vh;
  border: none;
}

.open-btn:hover {
  cursor: pointer;
}

.footer {
  padding-bottom: 5px;
}

.link-container {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  gap: 15
}
